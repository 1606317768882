import React, { useState, useEffect } from "react";
import { useProfileContext} from "../lib/contextLib";
import {useNavigate, useParams} from "react-router-dom";

import { useAlertContext } from "../lib/contextLib";
import { useGlobalLoaderContext } from "../lib/contextLib";
import {eventPut, getIsAdmin, loadEvent, updateEventInEvents} from "../lib/loadingLib";

import {  useFormik } from 'formik';
import * as Yup from 'yup';

import { BSButton } from "../components/Buttons";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea
} from "@chakra-ui/react";
import {DatePicker} from "react-rainbow-components";

import "./DrunkardMain.css"


export default function EventCreateEdit() {
    const navigate = useNavigate();
    const {events, profile, setEvents} = useProfileContext();
    const [response, setResponse] = useState(null);
    const {onOpen} = useAlertContext();
    const { isFullyLoading, setIsFullyLoading, setLoadingText} = useGlobalLoaderContext();
    //const [isLoading, setIsLoading] = useState(false);
    const [editEvent, setEditEvent] = useState({
        startDate: new Date(),
        endDate: new Date(),
        eventDescription: "",
        eventName: "",
    });

    /*ToDo: Make sure post only works for admins in backend!*/

    const { eventId } = useParams();

    useEffect( () => {
        if (response!=null){
            if (response.type ==="failed"){
                onOpen(response.type, response.message )
                //formik.resetForm();
                setIsFullyLoading(false);
                //setIsLoading(false);
                setLoadingText("");
            }
        }
      }, [response, onOpen, setIsFullyLoading, setLoadingText])



    useEffect(() => {
        async function onLoad() {
            setIsFullyLoading(true);
            setLoadingText("Lade Event");
            if (!(eventId === "new")){
                /*
                2. Make sure event is in eventsList. 
                    If not: Try to load from db.
                    If error onLoad: error notice that event does not exist.
                1. Make sure we are admin. Else: Error and re-route
                */
                let loadedEvent;
                if (events.hasOwnProperty(eventId)) {
                    loadedEvent = events[eventId]
                } else {
                    try {
                        loadedEvent = await loadEvent(eventId);
                    } catch(e) {
                        onOpen("failed", "Failed to load event. Potentially does not exist")
                        navigate(-1);
                    }
                }
                console.log("loaded Event:")
                console.log(loadedEvent);
                const thisIsAdmin=await getIsAdmin(eventId);
                if (!thisIsAdmin) {
                    onOpen("failed", "You are not allowed to edit this event")
                    navigate(-1);
                    return("null")
                }
                setEditEvent(loadedEvent);
            }
            setLoadingText("");
            setIsFullyLoading(false);
        }
        onLoad();
    }, [editEvent, eventId, events, navigate, onOpen, setIsFullyLoading, setLoadingText]);

    useEffect(() => {
        // Initialize formik values when editEvent changes
        //formik.setValues(get_formik_init());
    }, [editEvent]); 


    async function handleSubmitData(values, is_formik_dirty) {
        if (is_formik_dirty){
            //setIsLoading(true);
            setIsFullyLoading(true);
            setLoadingText("Event wird erstellt");

            const submit_dict = {...values};
            // 1. Craft proper event dict, depending on eventId==new or not

            if (eventId === "new") {
                submit_dict["drunkards"]= {[profile.userId]: {"redeemed": 0, "bierstrichCount": 0, "drunkardName": profile.drunkardName}}
            } else {
                // update elements not touched by the form
                for (const key of ["drunkards"]) {
                    submit_dict[key] = editEvent[key];
                }
            }
            submit_dict.startDate = (new Date(submit_dict.startDate)).toISOString().slice(0,10)
            submit_dict.endDate = (new Date(submit_dict.endDate)).toISOString().slice(0,10)
            // 2. Call API to put / update event
            const tmp_response = await eventPut(eventId, submit_dict);
            if (tmp_response.status === 200){
                let updateId;
                let updateEvent;
                if (eventId === "new"){
                    updateId = tmp_response.eventData.eventId;
                    updateEvent = tmp_response.eventData;
                    updateEvent["accepted"] = 1;
                    updateEvent["isAdmin"] = true;
                    const updateKeys = ["ownEventCount", "ownEventRedeemed", "totalCount", "totalRedeemed"];
                    updateKeys.forEach( item => {
                        updateEvent[item] = 0;
                    })
                    updateEvent["kings"] = {};
                } else {
                    submit_dict["kings"] = editEvent["kings"];
                    updateEvent = {
                        ...editEvent,
                        ...submit_dict,
                        drunkards: editEvent.drunkards
                    }
                    updateId = eventId;
                }
                const updatedEvents = updateEventInEvents(events, updateId, updateEvent);
                setEvents(updatedEvents);
                localStorage.setItem("events", JSON.stringify(updatedEvents));
                navigate(-1);
                setResponse({type: "success", content: {}, message: "Succcessfully created/updated event"})
            } else {
                //formik.setErrors({apiError: 'Error occured in API post -> event'})
                setResponse({type: "failed", content: {}, message: "Backend submission failed, try again (later)."})
            }
            //setIsLoading(false);
            setIsFullyLoading(false);
            setLoadingText("");
        } else { // form untouched -> go back
            navigate(-1);
        }
    }

    return(
        <>
        {isFullyLoading ? <></> : 
        <EventCreateForm
            editEvent={editEvent}
            submitHandler={handleSubmitData}
        />}
        </>        
    );

}

function EventCreateForm({editEvent, submitHandler}){
    const formik = useFormik({
        initialValues: {
            eventName: editEvent.eventName, 
            eventDescription: editEvent.eventDescription,
            startDate: editEvent.startDate,
            endDate: editEvent.endDate
        },
        onSubmit: async (values) => {
          console.log("Doing something with the button");
          await submitHandler(values, formik.dirty);
        },
        validationSchema: Yup.object({
            eventName: Yup.string().required("Benötigt").min(2),
            eventDescription: Yup.string().required("Benötigt").min(10),
            startDate: Yup.date().required("Gib ein Startdarum an"),
            endDate: Yup.date().required("Gib ein Enddatum an").test('AfterStart', "Event muss nach dem Start enden. Duh!", function(value) {
                if (editEvent.hasOwnProperty("eventId")){
                    return true;
                }
                const endDate = new Date(value);
                const startDate = new Date(formik.values.startDate);
                const startDateMidnight = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
                const endDateMidnight = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
                const isEndDateAfterStartDate = startDateMidnight.getTime() <= endDateMidnight.getTime();
                return (isEndDateAfterStartDate);
            }),
        }),
      });

    return(

        <div className="Profile">
            <div className="profileForm" >
                <form onSubmit={formik.handleSubmit} style={{display: 'flex', flexDirection: 'column' }}>
                <div className="profileRow">
                    <FormControl isInvalid={formik.touched.eventName && formik.errors.eventName}>
                        <FormLabel htmlFor="eventName">Name des Events</FormLabel>
                        <Input
                            id="eventName"
                            name="eventName"
                            value={formik.values.eventName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <FormErrorMessage>{formik.errors.eventName}</FormErrorMessage>
                    </FormControl>
                </div>
                <div className="profileRow">
                    <FormControl isInvalid={formik.touched.eventDescription && formik.errors.eventDescription}>
                        <FormLabel htmlFor="eventDescription">Eventbeschreibung</FormLabel>
                        <Textarea
                            id="eventDescription"
                            name="eventDescription"
                            height={250}
                            value={formik.values.eventDescription}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <FormErrorMessage>{formik.errors.eventDescription}</FormErrorMessage>
                    </FormControl>
                </div>
                <div className="profileRow">
                    <FormControl isInvalid={formik.touched.startDate && formik.errors.startDate}>
                        <FormLabel>Startdatum</FormLabel>
                        <DatePicker
                            id="startDate"
                            name="startDate"
                            onChange={date => {
                                const tzoffset = date.getTimezoneOffset();
                                date.setMinutes(date.getMinutes() - tzoffset);
                                formik.setFieldValue('startDate', date); 
                                formik.validateField('startDate');}}
                            onBlur={date => {formik.validateField('startDate')}}
                            showYearDropdown
                            dateFormat="MM/dd/yyyy"
                            popperPlacement="bottom-start"
                            value={formik.values.startDate}
                            locale="de-DE"
                        />
                        <FormErrorMessage>{formik.errors.startDate}</FormErrorMessage>
                    </FormControl>
                </div>
                <div className="profileRow">
                    <FormControl isInvalid={formik.touched.endDate && formik.errors.endDate}>
                        <FormLabel>Enddatum</FormLabel>
                        <DatePicker
                            id="endDate"
                            name="endDate"
                            onChange={date => {
                                const tzoffset = date.getTimezoneOffset();
                                date.setMinutes(date.getMinutes() - tzoffset);
                                formik.setFieldValue('endDate', date); 
                                formik.validateField('endDate');
                                }}
                            onBlur={date => {formik.validateField('endDate')}}
                            showYearDropdown
                            dateFormat="MM/dd/yyyy"
                            popperPlacement="bottom-start"
                            value={formik.values.endDate}
                            locale="de-DE"
                        />
                        <FormErrorMessage>{formik.errors.endDate}</FormErrorMessage>
                    </FormControl>
                </div>
                <div align="bottom" style={{ marginTop: 'auto' }}>
                    <BSButton 
                        type="submit" 
                        width="full" 
                        isLoading={false} 
                        style={{fontWeight: "bold"}} 
                        disabled={false}
                        >
                        {formik.dirty ? "Speichern" : "Zurück"}
                    </BSButton>
                </div>
                </form>
            </div>
        </div>
    )
}