import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {AdminMenuButton} from "../components/Buttons"

import {useProfileContext, useGlobalLoaderContext} from "../lib/contextLib";
import { useAlertContext } from "../lib/contextLib";
//import BierstrichList from "../components/BierstrichLists";

//import Tooltip from "react-bootstrap/Tooltip";
import {
    VStack,
    Flex,
    Box,
    Text,
    Center,
    Divider,
    Tab, Tabs, TabList, TabPanels, TabPanel

} from "@chakra-ui/react"
import { useDisclosure } from '@chakra-ui/react'
import { getIsAdmin, loadDataSingleEvent, updateEventInEvents, putDrunkardAdmin, acceptDrunkard, assert, createEventregistration, updateRedeemed, postBierstrich } from "../lib/loadingLib";
import { EventViewHeader } from "../components/Header";
import { EventCardBody, EventCardPublic } from "../components/Cards";
import { IoShareSocialOutline, IoPeopleOutline } from "react-icons/io5";
import { IoInformationOutline } from "react-icons/io5";
import BierstrichList from "../components/BierstrichLists";
import { DrunkardsSelectListComponent, ApplicantList, DrunkardsSelectListComponentButton } from "../components/DrunkardLists"
import { Mark5Icon } from "../icons/BierstrichIcons";

import { dateTimeView } from "../components/Helpers";

/*ToDo
    0. Update drunkard Names when loading event -> backend
    1. When handing out Bierstriche, update king dict and if own: my own stats. Does not seem to have worked.
    1. Test all potential cases: Not applied, applied but not accepted, accepted but not admin
    2. View is broken when we go to viewType(!=3) from viewType 3.
    3. Design of Multi-BS
    4. Move Modals into DrunkardListComponent
*/






export default function EventView() {

    const {profile, events, setEvents} = useProfileContext();
    const { eventId } = useParams();
    const [viewEvent, setViewEvent] = useState({});
    const [viewType, setViewType] = useState(0); // 0: Show own Bierstriche; 1: Show all Bierstriche; 2: Show Participants
    const [eventDrunkards, setEventDrunkards] = useState([]);
    const [allBierstriche, setAllBierstriche] = useState([]);
    const [bierstriche, setBierstriche] = useState([]);
    const {setIsFullyLoading, setLoadingText} = useGlobalLoaderContext();
    const {onOpen} = useAlertContext();
    const [adminButtonsLoading, setAdminButtonsLoading] = useState({});
    const [acceptButtonsLoading, setAcceptButtonsLoading] = useState({});
    const [excludeButtonsLoading, setExcludeButtonsLoading] = useState({});
    const [eliminateButtonsLoading, setEliminateButtonsLoading] = useState({});
    const [bsButtonsLoading, setBSButtonsLoading] = useState({});
    const [redeemButtonsLoading, setRedeemButtonsLoading] = useState({});
    const [appliedStatus, setAppliedStatus] = useState(-2); // -1: removed frome event. 0: applied. 1: accepted.
    const [applyLoading, setApplyLoading] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const { onClose: onRedeemClose } = useDisclosure();
    const { onClose: onBSClose } = useDisclosure();


    // =============================================
    // Loading stuff
    useEffect(() => {
        async function updateDrunkardsInEventContextStorage() {
            if (!viewEvent.drunkards || !eventDrunkards.length) return; // Add this check to prevent unnecessary updates
            const tmpEvent = {...viewEvent}; // Avoid direct mutation
            const filteredDrunkards = eventDrunkards.filter(item => !tmpEvent.drunkards.hasOwnProperty(item.userId));
            filteredDrunkards.forEach(item => {
                const { userId } = item;
                tmpEvent.drunkards[userId] = {"redeemed": 0, "bierstrichCount": 0, "drunkardName": item.drunkardName};
            });
            const updatedEvents = updateEventInEvents(events, eventId, tmpEvent);
            setEvents(updatedEvents);
            localStorage.setItem(eventId, JSON.stringify(updatedEvents));
        }

        const handleBeforeUnload = (event) => {
            updateDrunkardsInEventContextStorage();
            event.preventDefault();
            event.returnValue = ''; // Custom message to prompt unload
        };

        if (eventDrunkards.length > 0) {
            window.addEventListener("beforeunload", handleBeforeUnload);
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [eventDrunkards, viewEvent, events, setEvents, eventId]);

    const updateAndSetEvents = useCallback((updateEventId, updatedEvent) => {
        const updated_events = updateEventInEvents(events, updateEventId, updatedEvent);
        localStorage.setItem('events', JSON.stringify(updated_events));
        //setEvents(updated_events); // ToDo: Fix this!!
    }, [events]);

    const onLoad = useCallback(async () => {
        setIsFullyLoading(true);
        setLoadingText("Lade Event");

        //ToDo: Storage only for debugging. Remove later!
        //const doStorage = false;}
        const data_from_storage = () =>  {
            const storedData = localStorage.getItem(eventId);
            if (storedData) {
                const {
                    loadedEvent,
                    loadedBierstriche,
                    loadedBierstricheAll,
                    loadedDrunkards,
                } = JSON.parse(storedData);
                setIsFullyLoading(false);
                setLoadingText(null);

                return {
                    loadedEvent,
                    loadedBierstriche,
                    loadedBierstricheAll,
                    loadedDrunkards,
                };
            }
            else {
                return {}
            }
        }

        const thisIsAdmin=await getIsAdmin(eventId);
        let loadedData;
        try {
            loadedData = await loadDataSingleEvent(eventId, profile.userId, thisIsAdmin);
        } catch (e) {
            loadedData = data_from_storage();
        }
        const {
                loadedEvent,
                loadedBierstriche,
                loadedBierstricheAll,
                loadedDrunkards,
            } = loadedData;

        setIsFullyLoading(false);
        setLoadingText(null);

        return {
            loadedEvent,
            loadedBierstriche,
            loadedBierstricheAll,
            loadedDrunkards,
        };
    }, [eventId, profile.userId, setIsFullyLoading, setLoadingText]);

    useEffect(() => {
        const loadAndAwait = async () => {
            console.log("load stuff 1")
            const {
                loadedEvent,
                loadedBierstriche,
                loadedBierstricheAll,
                loadedDrunkards,
            } = await onLoad();
            console.log("load stuff 2")
            setBierstriche(loadedBierstriche);
            setEventDrunkards(loadedDrunkards);
            setAllBierstriche(loadedBierstricheAll);
            setAppliedStatus(loadedEvent.accepted);
            console.log("done setting stuff")
            localStorage.setItem('bierstriche', JSON.stringify(loadedBierstriche));
            localStorage.setItem(eventId, JSON.stringify({
                loadedEvent,
                loadedBierstriche,
                loadedBierstricheAll,
                loadedDrunkards,
            }));
            setViewEvent(loadedEvent);
            updateAndSetEvents(eventId, loadedEvent);
        };
        loadAndAwait();
    }, [eventId, onLoad, updateAndSetEvents]);


    // End Loadstuff
    // ========================================


    // view while loading
    if (!viewEvent || Object.keys(viewEvent).length === 0 || Object.keys(viewEvent.drunkards).length === 0 || appliedStatus === -2) {
        return <div className="Events"></div>;
    }


    const handleApply = async () => {
        console.log("Handle apply");
        setApplyLoading(true);
        let uuid=profile["userId"];
        console.log(profile);
        if (!uuid){
            uuid = JSON.parse(localStorage.getItem('profile')).userId;
        }
        console.log(uuid);
        const response = await createEventregistration(eventId, uuid);
        if (response.status === 200){
            onOpen("success", "Du hast dich zum Event angemeldet. Die Admins müssen deine Teilnahme noch bestätigen.")
            const tmpEvent = viewEvent;
            tmpEvent.accepted=0;
            updateAndSetEvents(eventId, tmpEvent);
            setAppliedStatus(0);
        }
        setApplyLoading(false);
        return ;
    }

    if (appliedStatus < 1){
        return <div className="Events">
            <EventViewHeader
                event={viewEvent}
                hideOnScroll={false}
            />
            <EventCardPublic 
                event={viewEvent}
                isLoading={applyLoading}
                disabled={appliedStatus === 0}
                handleApply={handleApply}
            />
        </div>
    }

    let RenderedList;
    let HeaderCard = <HeaderCardStandard />;
    switch (viewType) {
        case 0:
            HeaderCard = <HeaderCardStandard/>;
            RenderedList = <OwnBierstrichList/>;
            break;
        case 1:
            if (viewEvent.isAdmin){
                HeaderCard = <></>
                RenderedList = <DrunkardsList />;
            } else {
                HeaderCard = <HeaderCardStandard/>;
                RenderedList = <OwnBierstrichList/>;
            }
            break;
        case 2:
            if (viewEvent.isAdmin){
                HeaderCard = <></>
                RenderedList = <DrunkardsListSelectMulti/>;
            } else {
                HeaderCard = <HeaderCardStandard/>
                RenderedList = <OwnBierstrichList/>;
            }
            break;
        case 3:
            if (viewEvent.isAdmin){
                HeaderCard = <></> 
                RenderedList = <AllBierstrichList/>;
            } else {
                HeaderCard = <HeaderCardStandard/>
                RenderedList = <OwnBierstrichList/>;
            }
            break;
        default:
            HeaderCard = <HeaderCardStandard/>
            RenderedList = <OwnBierstrichList/>;
      }

    const eventBierstriche = bierstriche.filter(item => item.eventId ===  eventId)
        .sort((a, b) => (new Date(a.caseDate) > new Date(b.caseDate)) ? -1 : 1);


    const allEventBierstriche = (thisBierstriche) => {
        if (thisBierstriche.length===0){
            return [];
        } else {
            return thisBierstriche.filter(item => item.eventId ===  eventId)
        .sort((a, b) => (new Date(a.caseDate) > new Date(b.caseDate)) ? -1 : 1);
        }
    }

    const acceptedDrunkards = (drunkardList) => {
        return (drunkardList).filter(item => item.accepted)
        .sort((a, b) => (a.firstName > b.firstName) ? 1 : -1);
    }

    const applicantDrunkards = (drunkardList) => {
        return (drunkardList).filter(item => !item.accepted)
        .sort((a, b) => (a.firstName > b.firstName) ? 1 : -1);
    }

    /*const shiningEffect = {
        animation: "shining 1.5s infinite alternate",
        boxShadow: "0 0 10px rgba(0, 0, 255, 0.7)", // Adjust the color and intensity of the glow
    };*/

    function handleCopy(){
        const invitationLink = `http://bierstriche.de/events/${eventId}`
        navigator.clipboard.writeText(invitationLink)
        onOpen("success", "Link zum Event wurde ins Clipboard kopiert")
    }

    async function handleAdmin(userId){
        setAdminButtonsLoading({...adminButtonsLoading, [userId]: true});
        console.log(adminButtonsLoading);
        const listIdx = eventDrunkards.findIndex(item => item.userId === userId);
        const newIsAdmin=!eventDrunkards[listIdx].isAdmin
        if (listIdx !== -1){
            const adminDict={
                "content": {
                    "userId": userId,
                    "makeAdmin": newIsAdmin
                }
            }
            const response = await putDrunkardAdmin(adminDict, eventId);
            console.log(response.status);
            if (response.status === 200){
                const updatedEventDrunkards = [...eventDrunkards];
                updatedEventDrunkards[listIdx].isAdmin=newIsAdmin;
                setEventDrunkards(updatedEventDrunkards);
                //ToDo: Remove local storage set. For testing only
                localStorage.setItem(eventId, JSON.stringify({
                    viewEvent,
                    eventBierstriche,
                    allEventBierstriche,
                    updatedEventDrunkards,
                }));
                onOpen("success", newIsAdmin ? "Teilnehmer wurde zum Admin gemacht" : "Teilnehmer ist kein Admin mehr");
            }
        }
        setAdminButtonsLoading({...adminButtonsLoading, [userId]: false});
    }

    async function handleEliminate(userId){
        /*function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
          }
        const apicall = async () => {
            await sleep(3000);
        }*/
        console.log("handle Eliminate")
        setEliminateButtonsLoading({...eliminateButtonsLoading, [userId]: true});
        const listIdx = eventDrunkards.findIndex(item => item.userId === userId);
        const newIsAccepted = -1; //we use -1 to filter out rejected applicants. Needs to be considered in re-application
        const response = await acceptDrunkard(eventId, userId, "", newIsAccepted);
        if (response.status === 200){
            const updatedEventDrunkards = [...eventDrunkards];
            updatedEventDrunkards.splice(listIdx, 1);
            setEventDrunkards(updatedEventDrunkards);
        }
        //await apicall();
        console.log("handle Eliminate 2")
        setEliminateButtonsLoading({...eliminateButtonsLoading, [userId]: false});
    }

    /*function updateDrunkardInEventsContextStorage(userId, updateDict){
        const tmpEvent = viewEvent;
        tmpEvent.drunkards[userId] = updateDict;
        setViewEvent(tmpEvent);
        const tmpEvents = {...events};
        tmpEvents[eventId] = tmpEvent;
        setEvents(tmpEvents);
        localStorage.setItem(eventId, JSON.stringify({
            tmpEvent,
            eventBierstriche,
            allEventBierstriche,
            eventDrunkards,
        }));
    }*/


    async function handleAccept(userId, accept=true){
        if (accept){
            setAcceptButtonsLoading({...acceptButtonsLoading, [userId]: true});
        } else {
            setExcludeButtonsLoading({...excludeButtonsLoading, [userId]: true});
        }
        let thisDrunkardName;
        const listIdx = eventDrunkards.findIndex(item => item.userId === userId);
        const thisDrunkard = eventDrunkards[listIdx];
        const newIsAccepted = !thisDrunkard.accepted;
        assert(newIsAccepted === accept, "Something went wrong in accepted state");
        if (thisDrunkard) {
            thisDrunkardName =thisDrunkard.drunkardName
        } else {
            thisDrunkardName = ""
        }
        const response = await acceptDrunkard(eventId, userId, thisDrunkardName, accept);

        if (response.status === 200){
            const updatedEventDrunkards = [...eventDrunkards];
            updatedEventDrunkards[listIdx].accepted=newIsAccepted;
            setEventDrunkards(updatedEventDrunkards);
            /*if (accept){
                const updateDict = {"redeemed": 0, "bierstrichCount": 0, "drunkardName": thisDrunkardName};
                //updateDrunkardInEventsContextStorage(userId, updateDict);
            }*/
            //ToDo: Remove local storage set. For testing only
            localStorage.setItem(eventId, JSON.stringify({
                viewEvent,
                eventBierstriche,
                allEventBierstriche,
                updatedEventDrunkards,
            }));
            onOpen("success", newIsAccepted ? "Teilnehmer wurde zum Event hinzugefügt" : "Teilnehmer wurde vom Event entfernt");
        }
        if (accept){
            setAcceptButtonsLoading({...acceptButtonsLoading, [userId]: false});
        } else {
            setExcludeButtonsLoading({...excludeButtonsLoading, [userId]: false});
        }
    }


    async function handleRedeemConfirm(drunkardForRedeem, redeemCt){
        onRedeemClose();
        console.log("Redeem" + redeemCt + "striche");
        const userId = drunkardForRedeem.userId;
        setRedeemButtonsLoading({...redeemButtonsLoading, [userId]: true});
        const updatedRedeemedCt = drunkardForRedeem.ownEventRedeemed + redeemCt;
        const response = await updateRedeemed(eventId, userId, updatedRedeemedCt);

        if (response.status === 200){
            // Update drunkard in eventDrunkards
            const listIdx = eventDrunkards.findIndex(item => item.userId === userId);
            const updatedEventDrunkards = [...eventDrunkards];
            updatedEventDrunkards[listIdx].ownEventRedeemed=updatedRedeemedCt;
            setEventDrunkards(updatedEventDrunkards);
            // 2. Update totalRedeemed in viewEvent
            const tmp_viewEvent = viewEvent;
            tmp_viewEvent.totalRedeemed += redeemCt;
            if (profile.userId === userId){
                tmp_viewEvent.ownEventRedeemed += redeemCt;
            }
            setViewEvent(tmp_viewEvent);

            // 3. Update event in events
            //shifted to handleExit()
            /*const updatedEvents = updateEventInEvents(events, eventId, tmp_viewEvent);
            setEvents(updatedEvents);
            localStorage.setItem("events", JSON.stringify(updatedEvents));*/
        }

        setRedeemButtonsLoading({...redeemButtonsLoading, [userId]: false});

        /* ToDo: 
            1. Adapt redeemed in participant list
            2. If self: Adapt redeemd in viewEvent.ownEventRedeemed
            3. Update redeemed in event.drunkards
            3.b update Event in DB
            3.a update viewEvent in Events
            3.c update Events in storage

            4.Later, when redeemed is part of eventregistration: Only 1 and 2, then update eventregistration in db

        */
    }


    async function handleBSConfirm(bsDict, drunkards=[]){
        setViewType(1);
        onBSClose();

        const userIds = drunkards.map(item => item.userId);

        const tmp_buttonsLoading = {...bsButtonsLoading};
        drunkards.forEach(item => {
            tmp_buttonsLoading[item.userId] = true
        })
        setBSButtonsLoading(tmp_buttonsLoading);

        const thisBierstrich ={
            "userIds": userIds,
            "policeId": profile.userId,
            "count": bsDict.bsCount,
            "caseDate": bsDict.bsDate,
            "eventId": viewEvent.eventId,
            "eventName": viewEvent.eventName,
            "reason": bsDict.bsReason,
        }
        const thisContentDict = {
            "content": thisBierstrich
        }

        const response = await postBierstrich(thisContentDict) ; // here call BSput in endpoint

        const newReturnBSDict = response.bsData ;
        const updatedBSList = allBierstriche;
        if (response.status === 200){

            // 2. Update totalCount in viewEvent
            const tmp_viewEvent = viewEvent;

            // ToDo: Loop over drunkards
            const updatedEventDrunkards = [...eventDrunkards];
            drunkards.forEach(drunkardForUpdate => {
                tmp_viewEvent.totalCount += bsDict.bsCount;
                const updatedOwnEventCount = drunkardForUpdate.ownEventCount + bsDict.bsCount;
                const listIdx = eventDrunkards.findIndex(item => item.userId === drunkardForUpdate.userId);
                updatedEventDrunkards[listIdx].ownEventCount=updatedOwnEventCount;

                if (profile.userId === drunkardForUpdate.userId){
                    tmp_viewEvent.ownEventCount += bsDict.bsCount;
                    const updatedOwnBS = [...bierstriche, newReturnBSDict[profile.userId]];
                    setBierstriche(updatedOwnBS);
                    localStorage.setItem("bierstriche", JSON.stringify(updatedOwnBS));
                }
                // 2.a) Update kings in viewEvent -> ToDo: Update such that king is set correctly in case of multiple users that got Bierstrich  
                const oldKingBsCount = tmp_viewEvent.kings[Object.keys(tmp_viewEvent.kings)[0]]
                console.log(oldKingBsCount);
                if (updatedOwnEventCount === oldKingBsCount){
                    tmp_viewEvent.kings[drunkardForUpdate.drunkardName] = updatedOwnEventCount;
                } else if (updatedOwnEventCount > oldKingBsCount){
                    tmp_viewEvent.kings = {[drunkardForUpdate.drunkardName]: updatedOwnEventCount};
                }
                // push bs to BSList
                updatedBSList.push(newReturnBSDict[drunkardForUpdate.userId]);
            })
            setEventDrunkards(updatedEventDrunkards);
            setViewEvent(tmp_viewEvent);

            // 3. Update event in events
            //Shifted to handleExit()
            /*const updatedEvents = updateEventInEvents(events, eventId, tmp_viewEvent);
            setEvents(updatedEvents);
            localStorage.setItem("events", JSON.stringify(updatedEvents));*/

            //4. Add BS to event BS List

            setAllBierstriche(updatedBSList);

        }
        //setBSButtonsLoading({...bsButtonsLoading, [userId]: false});
        setBSButtonsLoading({});
    }

    function HeaderCardStandard(){
        return (
            <>
                <Box p={4} textAlign="center"  textStyle='cardText'>
                    <Text mb={0}>{dateTimeView(viewEvent.startDate, false)} bis {dateTimeView(viewEvent.endDate, false)}</Text>
                </Box>
                <Divider p={0} m={0}/>
                <Box p={4} textAlign="center" boxShadow='lg'>
                    <EventCardBody
                        myuuid={profile.userId}
                        event={viewEvent}
                        bg="white"
                    />
                </Box>
            </>
        )
    }

    //ToDo: handle all updates from lines 495-496 and similar lines from redeem in exit
    function prepExit(){
        const updatedEvents = updateEventInEvents(events, eventId, viewEvent);
        setEvents(updatedEvents);
        localStorage.setItem("events", JSON.stringify(updatedEvents));
    }

    return(
        <div className="Events" >
            {/* Header */}
            <EventViewHeader
                event={viewEvent}
                preCloseClick={prepExit}
                hideOnScroll={false}
            />


                {/*<VStack bg="white" spacing={0} width="100%" align="center" alignItems="justify" m={0} position="fixed" zIndex={1000} ref={myRef}>*/}
                    {HeaderCard}
                <VStack bg="white" spacing={0} width="100%" align="center" alignItems="justify" m={0} >
                </VStack>
                {/*<Box h={`${headerHeight}px`} bg="white" mb={4}/>*/}

                <VStack spacing={0} width="100%" alignItems="justify"> 
                    {RenderedList}
                </VStack>



        {viewEvent.isAdmin ? <>
            {/* Buttons */}
            <Box h="80px"/>
            <Flex
                width="100%"
                position="fixed"
                bottom={0}
                left={0}
                right={0}
                m={0}
                bg="white"
                boxShadow="md"
                height="80px"
                zIndex="sticky"
                >
                <Flex textStyle="cardText" alignItems='flex-end' justify="space-between" width="full" px={0} m={0} align='bottom' mt='auto'>
                <AdminMenuButton 
                        onClick={()=>{setViewType(0); setViewType(0);}}
                    >
                        <IoInformationOutline size="30px"/>
                    </AdminMenuButton>
                    <AdminMenuButton
                        onClick={()=>{setViewType(1)}}
                        >
                        <Center>
                            <Flex >
                            <IoPeopleOutline size="40px"/>
                            </Flex>
                        </Center>
                    </AdminMenuButton>
                    {/*<Box boxShadow="xl" flex={1.5}>
                        <AdminMenuButton 
                            h={20}
                            width="full"
                            boxShadow="md"
                            variant="solid"
                            style={shiningEffect}
                            p={0}
                            m={0} 
                            onClick={()=>{setViewType(2)}}
                        >
                           <Mark5Icon size="55px"/> <IoBeerOutline size="55px"/>
                        </AdminMenuButton>
                    </Box>*/}
                    <AdminMenuButton
                        textStyle="cardText"
                        onClick={()=>{setViewType(0); setViewType(3)}}
                    >
                        <Center>
                            <VStack spacing={0}>
                                <Flex>
                                <IoPeopleOutline size="30px"/>
                                </Flex>
                                <Flex>
                                <Mark5Icon/><Mark5Icon/>
                                </Flex>
                            </VStack>
                        </Center>
                    </AdminMenuButton>
                    <AdminMenuButton 
                        onClick={()=>handleCopy()}
                        >
                        <IoShareSocialOutline size="40px"/>
                    </AdminMenuButton>
                </Flex>
            </Flex>
        </>:<></>}
        </div>
    );

    function OwnBierstrichList() {
        return(
            <>
                {eventBierstriche.length === 0 ? <>
                <Box p={4} textAlign="center">
                    <Text mb={0}>Du hast noch keine Bierstriche. Streber!</Text>
                </Box>

            </>:<>
                <Box p={4} textAlign="center">
                    <Text mb={0}>Deine Bierstriche</Text>
                </Box>
                <BierstrichList mylist={eventBierstriche} drunkardData={viewEvent.drunkards} fullParticipantData={eventDrunkards} adminView={false} includeSearch={false}/>

            </>}
            </>
        );
    }

    function AllBierstrichList(){
        const thisBierstriche = allEventBierstriche(allBierstriche);
        if (!viewEvent.isAdmin) {return <></>;}
        return(
            <>
                {thisBierstriche.length === 0 ? <>
                <Box p={4} textAlign="center">
                    <Text mb={0}>Du hast noch keine Bierstriche verteilt! Buuuuh!</Text>
                </Box>
            </>:<>
                <Box p={4} textAlign="center">
                    <Text mb={0}>Alle Bierstriche</Text>
                </Box>
                <BierstrichList mylist={thisBierstriche} drunkardData={viewEvent.drunkards} fullParticipantData={eventDrunkards} adminView={viewEvent.isAdmin} includeSearch={true}/>
            </>}
            </>

        );
    }

    function DrunkardsListSelectMulti({...props}){
        if (!viewEvent.isAdmin) {return <></>;}
        return <>
                <DrunkardsSelectListComponent  
                    drunkards={acceptedDrunkards(eventDrunkards)}
                    handleBSConfirm={handleBSConfirm} 
                />
            </>
    }

    function DrunkardsList({ ...props}){
        const applicants = applicantDrunkards(eventDrunkards);
        if (!viewEvent.isAdmin) {return <></>;}
        return <>
            <Box p={0}>
                <Tabs isFitted variant="enclosed" index={selectedTabIndex} onChange={(index) => setSelectedTabIndex(index)}>
                <TabList mb="0">
                    <Tab>Teilnehmer</Tab>
                    <Tab isDisabled={applicants.length === 0}>Offene Anfragen</Tab>
                </TabList>
                <TabPanels p={0}>
                    <TabPanel p={0}>
                        <DrunkardsSelectListComponentButton
                            drunkards={acceptedDrunkards(eventDrunkards)}
                            event={viewEvent}
                            handleAdmin={handleAdmin}
                            isAdminsLoading={adminButtonsLoading}
                            handleExclude={handleAccept}
                            isExcludeLoading={excludeButtonsLoading}
                            handleRedeemConfirm={handleRedeemConfirm}
                            isRedeemLoading={redeemButtonsLoading}
                            isBSLoading={bsButtonsLoading}
                            handleBSConfirm={handleBSConfirm}
                        />
                    </TabPanel>
                    <TabPanel p={0}>
                        {applicants.length > 0 ? <>
                            <ApplicantList
                                drunkards={applicants}
                                handleAccept={handleAccept}
                                isAcceptLoading={acceptButtonsLoading}
                                handleEliminate={handleEliminate}
                                isEliminateLoading={eliminateButtonsLoading}
                            />
                        </>:
                        <>
                            <Center>
                                <Text>
                                    Keine Anfragen
                                </Text>
                            </Center>
                        </>}
                    </TabPanel>
                </TabPanels>
                </Tabs>
            </Box>
        </>;
    }

}
